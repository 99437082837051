/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

import { type CTALink, CTALinkSchema } from './cta-link'
import { type RelatedLink, RelatedLinkSchema } from './related-link'

/* TypeScript */
export type RelatedLinkProps = {
  technicalName?: string
  title?: string
  subtitle?: string
  links: CTALink[] | RelatedLink[]
  align?: 'Left' | 'Center'
  display?: 'Wrap' | 'Inline-Scroll'
  size?: 'Small' | 'Medium'
}

/* Schemas */
export const RelatedLinkPropsSchema = z.object({
  technicalName: z.string().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  links: z.union([z.array(CTALinkSchema), z.array(RelatedLinkSchema)]),
  align: z.enum(['Left', 'Center']).optional(),
  display: z.enum(['Wrap', 'Inline-Scroll']).optional(),
  size: z.enum(['Small', 'Medium']).optional(),
})

/* Schemas validation */
type TestRelatedLinkProps = ExpectTrue<
  Equal<RelatedLinkProps, z.infer<typeof RelatedLinkPropsSchema>>
>
