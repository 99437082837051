/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

/* TypeScript */
export type IconName =
  | 'IconAddToCart'
  | 'IconAlert'
  | 'IconArchive'
  | 'IconArrowDown'
  | 'IconArrowDownLeft'
  | 'IconArrowDownRight'
  | 'IconArrowLeft'
  | 'IconArrowRight'
  | 'IconArrowUp'
  | 'IconArrowUpLeft'
  | 'IconArrowUpRight'
  | 'IconAttachment'
  | 'IconAvatar'
  | 'IconAvatarFilled'
  | 'IconBackMarket'
  | 'IconBackMarketApp'
  | 'IconBackMarketAvatar'
  | 'IconBadge'
  | 'IconBank'
  | 'IconBattery'
  | 'IconBellAlert'
  | 'IconBellDisabled'
  | 'IconBellFilled'
  | 'IconBellOutlined'
  | 'IconBill'
  | 'IconBluetooth'
  | 'IconBurger'
  | 'IconCalendar'
  | 'IconCalendarWithCheck'
  | 'IconCamera'
  | 'IconCardView'
  | 'IconCellSignal'
  | 'IconChargingWire'
  | 'IconChart'
  | 'IconCharter'
  | 'IconCheckInCircle'
  | 'IconCheckInCircleFilled'
  | 'IconCheckLarge'
  | 'IconCheckSmall'
  | 'IconChevronDown'
  | 'IconChevronLeft'
  | 'IconChevronRight'
  | 'IconChevronUp'
  | 'IconClock'
  | 'IconComment'
  | 'IconCompass'
  | 'IconComputerMouse'
  | 'IconContact'
  | 'IconController'
  | 'IconCookie'
  | 'IconCopy'
  | 'IconCpu'
  | 'IconCreditCard'
  | 'IconCross'
  | 'IconCrossInCircle'
  | 'IconCrossInCircleFilled'
  | 'IconCrossSmall'
  | 'IconCrosshair'
  | 'IconCupcake'
  | 'IconCurrencyAustralianDollar'
  | 'IconCurrencyDollar'
  | 'IconCurrencyEuro'
  | 'IconCurrencyPound'
  | 'IconCurrencySwedishKrone'
  | 'IconCurrencyYen'
  | 'IconCycle'
  | 'IconDesign'
  | 'IconDiamond'
  | 'IconDoubleChevronUp'
  | 'IconDownload'
  | 'IconEdit'
  | 'IconEiffelTower'
  | 'IconEqual'
  | 'IconEqualizer'
  | 'IconEqualizerSmall'
  | 'IconExpand'
  | 'IconExport'
  | 'IconExternalLink'
  | 'IconEyeClosed'
  | 'IconEyeOpen'
  | 'IconEyes'
  | 'IconFactory'
  | 'IconFigma'
  | 'IconFile'
  | 'IconFingerprint'
  | 'IconFire'
  | 'IconFlag'
  | 'IconFloat'
  | 'IconGear'
  | 'IconGearUpdate'
  | 'IconGift'
  | 'IconGitHubFilled'
  | 'IconGlasses'
  | 'IconGlobe'
  | 'IconGoldenGate'
  | 'IconGpu'
  | 'IconGrowth'
  | 'IconHdmi'
  | 'IconHeadset'
  | 'IconHeart'
  | 'IconHeartBroken'
  | 'IconHeartDiagnostic'
  | 'IconHeartExploded'
  | 'IconHeartFilled'
  | 'IconHome'
  | 'IconHomeFilled'
  | 'IconImage'
  | 'IconInfo'
  | 'IconInfoFilled'
  | 'IconKeyboard'
  | 'IconLanguage'
  | 'IconLaptopCamera'
  | 'IconLeaf'
  | 'IconLightbulb'
  | 'IconListView1'
  | 'IconListView2'
  | 'IconLocationPinFilled'
  | 'IconLocationPinOutlined'
  | 'IconLockLocked'
  | 'IconLockUnlocked'
  | 'IconLogOut'
  | 'IconMagsafe'
  | 'IconMail'
  | 'IconMedal'
  | 'IconMegaphone'
  | 'IconMinus'
  | 'IconMinusInCircle'
  | 'IconMoney'
  | 'IconMoreVertical'
  | 'IconMove'
  | 'IconOfficial'
  | 'IconOfficialFilled'
  | 'IconPackage'
  | 'IconPaintBrush'
  | 'IconPeople'
  | 'IconPerformance'
  | 'IconPlug'
  | 'IconPlus'
  | 'IconPlusInCircle'
  | 'IconPower'
  | 'IconPowerAdapterApple'
  | 'IconPriceTag'
  | 'IconPriceUpdate'
  | 'IconPrint'
  | 'IconPromo'
  | 'IconQuality'
  | 'IconReassuranceParcelOutlined'
  | 'IconRecommendation'
  | 'IconRefresh'
  | 'IconRefund'
  | 'IconRepairHome'
  | 'IconRepairMailIn'
  | 'IconRepairSelf'
  | 'IconRepairStore'
  | 'IconRocket'
  | 'IconScratches'
  | 'IconSdhcCard'
  | 'IconSearch'
  | 'IconSearchFilled'
  | 'IconSend'
  | 'IconShare'
  | 'IconShareAndroid'
  | 'IconShop'
  | 'IconShoppingBag'
  | 'IconSim'
  | 'IconSiren'
  | 'IconSmartphone'
  | 'IconSmartphoneBroken'
  | 'IconSmartphoneCamera'
  | 'IconSmartphoneScreenSize'
  | 'IconSort'
  | 'IconSos'
  | 'IconSoundLow'
  | 'IconSoundOff'
  | 'IconSoundOn'
  | 'IconSparkles'
  | 'IconSpinner'
  | 'IconStarFilled'
  | 'IconStarHalf'
  | 'IconStarOutlined'
  | 'IconSunBeam'
  | 'IconSunFace'
  | 'IconSwap'
  | 'IconSwapFilled'
  | 'IconThumbsDownFilled'
  | 'IconThumbsDownOutlined'
  | 'IconThumbsUpFilled'
  | 'IconThumbsUpOutlined'
  | 'IconTools'
  | 'IconTouch'
  | 'IconTouchbar'
  | 'IconTrade'
  | 'IconTrash'
  | 'IconTrophy'
  | 'IconTruck'
  | 'IconTruckExpress'
  | 'IconTv'
  | 'IconUndo'
  | 'IconUpload'
  | 'IconWarning'
  | 'IconWatch'
  | 'IconWaterdrop'
  | 'IconWifi'

export type IconColor =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'danger'
  | 'warning'

export type IconSize = 'small' | 'medium' | 'large'

export type CmsIcon = {
  icon: IconName
  color?: IconColor
  size?: IconSize
}

/* Schemas */
export const IconNameSchema = z.enum([
  'IconAddToCart',
  'IconAlert',
  'IconArchive',
  'IconArrowDown',
  'IconArrowDownLeft',
  'IconArrowDownRight',
  'IconArrowLeft',
  'IconArrowRight',
  'IconArrowUp',
  'IconArrowUpLeft',
  'IconArrowUpRight',
  'IconAttachment',
  'IconAvatar',
  'IconAvatarFilled',
  'IconBackMarket',
  'IconBackMarketApp',
  'IconBackMarketAvatar',
  'IconBadge',
  'IconBank',
  'IconBattery',
  'IconBellAlert',
  'IconBellDisabled',
  'IconBellFilled',
  'IconBellOutlined',
  'IconBill',
  'IconBluetooth',
  'IconBurger',
  'IconCalendar',
  'IconCalendarWithCheck',
  'IconCamera',
  'IconCardView',
  'IconCellSignal',
  'IconChargingWire',
  'IconChart',
  'IconCharter',
  'IconCheckInCircle',
  'IconCheckInCircleFilled',
  'IconCheckLarge',
  'IconCheckSmall',
  'IconChevronDown',
  'IconChevronLeft',
  'IconChevronRight',
  'IconChevronUp',
  'IconClock',
  'IconComment',
  'IconCompass',
  'IconComputerMouse',
  'IconContact',
  'IconController',
  'IconCookie',
  'IconCopy',
  'IconCpu',
  'IconCreditCard',
  'IconCross',
  'IconCrossInCircle',
  'IconCrossInCircleFilled',
  'IconCrossSmall',
  'IconCrosshair',
  'IconCupcake',
  'IconCurrencyAustralianDollar',
  'IconCurrencyDollar',
  'IconCurrencyEuro',
  'IconCurrencyPound',
  'IconCurrencySwedishKrone',
  'IconCurrencyYen',
  'IconCycle',
  'IconDesign',
  'IconDiamond',
  'IconDoubleChevronUp',
  'IconDownload',
  'IconEdit',
  'IconEiffelTower',
  'IconEqual',
  'IconEqualizer',
  'IconEqualizerSmall',
  'IconExpand',
  'IconExport',
  'IconExternalLink',
  'IconEyeClosed',
  'IconEyeOpen',
  'IconEyes',
  'IconFactory',
  'IconFigma',
  'IconFile',
  'IconFingerprint',
  'IconFire',
  'IconFlag',
  'IconFloat',
  'IconGear',
  'IconGearUpdate',
  'IconGift',
  'IconGitHubFilled',
  'IconGlasses',
  'IconGlobe',
  'IconGoldenGate',
  'IconGpu',
  'IconGrowth',
  'IconHdmi',
  'IconHeadset',
  'IconHeart',
  'IconHeartBroken',
  'IconHeartDiagnostic',
  'IconHeartExploded',
  'IconHeartFilled',
  'IconHome',
  'IconHomeFilled',
  'IconImage',
  'IconInfo',
  'IconInfoFilled',
  'IconKeyboard',
  'IconLanguage',
  'IconLaptopCamera',
  'IconLeaf',
  'IconLightbulb',
  'IconListView1',
  'IconListView2',
  'IconLocationPinFilled',
  'IconLocationPinOutlined',
  'IconLockLocked',
  'IconLockUnlocked',
  'IconLogOut',
  'IconMagsafe',
  'IconMail',
  'IconMedal',
  'IconMegaphone',
  'IconMinus',
  'IconMinusInCircle',
  'IconMoney',
  'IconMoreVertical',
  'IconMove',
  'IconOfficial',
  'IconOfficialFilled',
  'IconPackage',
  'IconPaintBrush',
  'IconPeople',
  'IconPerformance',
  'IconPlug',
  'IconPlus',
  'IconPlusInCircle',
  'IconPower',
  'IconPowerAdapterApple',
  'IconPriceTag',
  'IconPriceUpdate',
  'IconPrint',
  'IconPromo',
  'IconQuality',
  'IconReassuranceParcelOutlined',
  'IconRecommendation',
  'IconRefresh',
  'IconRefund',
  'IconRepairHome',
  'IconRepairMailIn',
  'IconRepairSelf',
  'IconRepairStore',
  'IconRocket',
  'IconScratches',
  'IconSdhcCard',
  'IconSearch',
  'IconSearchFilled',
  'IconSend',
  'IconShare',
  'IconShareAndroid',
  'IconShop',
  'IconShoppingBag',
  'IconSim',
  'IconSiren',
  'IconSmartphone',
  'IconSmartphoneBroken',
  'IconSmartphoneCamera',
  'IconSmartphoneScreenSize',
  'IconSort',
  'IconSos',
  'IconSoundLow',
  'IconSoundOff',
  'IconSoundOn',
  'IconSparkles',
  'IconSpinner',
  'IconStarFilled',
  'IconStarHalf',
  'IconStarOutlined',
  'IconSunBeam',
  'IconSunFace',
  'IconSwap',
  'IconSwapFilled',
  'IconThumbsDownFilled',
  'IconThumbsDownOutlined',
  'IconThumbsUpFilled',
  'IconThumbsUpOutlined',
  'IconTools',
  'IconTouch',
  'IconTouchbar',
  'IconTrade',
  'IconTrash',
  'IconTrophy',
  'IconTruck',
  'IconTruckExpress',
  'IconTv',
  'IconUndo',
  'IconUpload',
  'IconWarning',
  'IconWatch',
  'IconWaterdrop',
  'IconWifi',
])

export const IconColorSchema = z.enum([
  'primary',
  'secondary',
  'success',
  'info',
  'danger',
  'warning',
])

export const IconSizeSchema = z.enum(['small', 'medium', 'large'])

export const CmsIconSchema = z.object({
  icon: IconNameSchema,
  color: IconColorSchema.optional(),
  size: IconSizeSchema.optional(),
})

/* Schemas validation */
type TestCmsIcon = ExpectTrue<Equal<CmsIcon, z.infer<typeof CmsIconSchema>>>
