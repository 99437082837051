/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import { type CTAStyling, CTAStylingSchema } from '../shared/cta-styling'
import type { Equal, ExpectTrue } from '../types/validation'

import { type ContentLink, ContentLinkSchema } from './content-link'
import { type CmsIcon, CmsIconSchema } from './icon'

/* TypeScript */
export type RelatedLink = {
  label: string
  icon?: CmsIcon
  link: ContentLink
  mood: CTAStyling
}

/* Schemas */
export const RelatedLinkSchema = z.object({
  label: z.string(),
  icon: CmsIconSchema.optional(),
  link: ContentLinkSchema,
  mood: CTAStylingSchema,
})

/* Schemas validation */
type TestRelatedLink = ExpectTrue<
  Equal<RelatedLink, z.infer<typeof RelatedLinkSchema>>
>
