/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from 'zod'

import type { Equal, ExpectTrue } from '../types/validation'

export type Tone = 'mindaro' | 'dark-tones' | 'mid-tones'
export type Mood = 'bush' | 'coffee' | 'tangaroa' | 'violet'

export type CTAStyling = `${Tone}_${Mood}` | 'primary' | 'secondary'

/* Schemas */
export const ToneSchema = z.enum(['mindaro', 'dark-tones', 'mid-tones'])
export const MoodSchema = z.enum(['bush', 'coffee', 'tangaroa', 'violet'])
export const CTAStylingSchema = z.enum([
  'primary',
  'secondary',
  'mindaro_bush',
  'mindaro_coffee',
  'mindaro_tangaroa',
  'mindaro_violet',
  'dark-tones_bush',
  'dark-tones_coffee',
  'dark-tones_tangaroa',
  'dark-tones_violet',
  'mid-tones_bush',
  'mid-tones_coffee',
  'mid-tones_tangaroa',
  'mid-tones_violet',
])

/* Schemas validation */
type TestCTAStyling = ExpectTrue<
  Equal<CTAStyling, z.infer<typeof CTAStylingSchema>>
>
type TestTone = ExpectTrue<Equal<Tone, z.infer<typeof ToneSchema>>>
type TestMood = ExpectTrue<Equal<Mood, z.infer<typeof MoodSchema>>>
